import React from 'react'
import { node, string } from 'prop-types'

import { Title as TitleStyled } from './style'

const propTypes = {
  children: node,
  color: string,
  variant: string,
}

const defaultProps = {
  color: 'color.neutral.90',
}

const Title = ({ children, color, variant, ...others }) => {
  if (!children) return null

  return (
    <TitleStyled variant={{ _: 'exceptionMdBold' }} color={color} {...others}>
      {children}
    </TitleStyled>
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps

export { Title }
