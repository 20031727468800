import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'

const Description = styled(Typography)`
  text-align: center;
  width: 70%;
  margin: auto;
  margin-bottom: 48px;
`
export { Description }
