import { node } from 'prop-types'

import { Container } from './styles'

const propTypes = { children: node }

const Wrapper = ({ children, ...others }) => (
  <section {...others}>
    <Container>{children}</Container>
  </section>
)

Wrapper.propTypes = propTypes

export default Wrapper
