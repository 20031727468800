import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const MediasGrid = styled.div`
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  display: grid;
  grid-column-gap: 40px;

  ${media.up('5xl')} {
    grid-column-gap: 80px;
  }
`

export default MediasGrid
