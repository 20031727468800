import React from 'react'
import { object } from 'prop-types'

import { LazyImage } from 'components/LazyImage'

import { ImageLinkWrapper } from './style'

const propTypes = {
  logoItem: object,
  ref: object,
}

const artDirectionImages = [
  {
    sizes: '(max-width: 800px) 100vw, 800px',
    media: '(min-width: 1024px)',
  },
  {
    sizes: '(max-width: 800px) 100vw, 800px',
    media: '(min-width: 768px)',
  },
]

const getArtDirectoryForLogo = (desktop, mobile) => {
  return [
    { ...artDirectionImages[0], ...desktop },
    { ...artDirectionImages[1], ...mobile },
  ]
}

const ImageWrapper = ({ logoItem, ref, ...others }) => {
  return (
    <ImageLinkWrapper ref={ref} {...others}>
      {logoItem?.logo && logoItem?.logoMobile && (
        <LazyImage
          artDirection={getArtDirectoryForLogo(
            logoItem.logo?.fluid,
            logoItem.logoMobile?.fluid,
          )}
          alt={logoItem.logo?.alt || logoItem?.name}
        />
      )}
    </ImageLinkWrapper>
  )
}

ImageWrapper.propTypes = propTypes

export { ImageWrapper }
