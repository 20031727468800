import React from 'react'
import { string } from 'prop-types'

import { Description as DescriptionStyled } from './style'

const propTypes = {
  color: string,
  variant: string,
}

const defaultProps = {
  color: 'color.neutral.60',
}

const Description = ({ color, variant, ...others }) => {
  return (
    <DescriptionStyled
      variant={{ _: 'bodyLgRegular' }}
      color={color}
      {...others}
    />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps

export { Description }
