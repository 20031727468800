import React from 'react'
import { arrayOf, object, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ImageWrapper } from '../components/ImageWrapper'
import Wrapper from '../components/Wrapper'

import { MediasGrid } from './styles'

const propTypes = {
  logos: arrayOf(
    shape({
      description: string,
      logo: object,
      logoMobile: object,
      name: string,
    }),
  ),
}

const defaultProps = {
  logos: [],
}

const MediaLogos = ({ logos, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  const handleClick = ({ name }, index) => {
    if (!name) return

    triggerEvent({
      label: `${index + 1} | ${name}`,
      pageInteraction: true,
    })
  }

  return (
    <Wrapper {...others}>
      <MediasGrid>
        {logos?.map((logoItem, index) => (
          <ImageWrapper
            key={index}
            logoItem={logoItem}
            onClick={() => handleClick(logoItem, index)}
          />
        ))}
      </MediasGrid>
    </Wrapper>
  )
}

MediaLogos.propTypes = propTypes
MediaLogos.defaultProps = defaultProps

export default MediaLogos
