import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

export const Container = styled(CreditasContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 120px;

  ${media.up('5xl')} {
    min-height: 200px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`
