import { styled } from '@creditas-ui/system'

const ImageLinkWrapper = styled.div`
  cursor: pointer;
  position: relative;

  .gatsby-image-wrapper {
    min-width: 68px;
  }

  img {
    top: 50% !important;
    transform: translateY(-50%) !important;
    object-fit: scale-down !important;
    max-height: 85%;
    min-width: 68px;
  }
`

export { ImageLinkWrapper }
