import { useCallback, useEffect, useRef, useState } from 'react'
import { arrayOf, object, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { isInViewport } from 'ui/utils/isInViewport'

import { Description } from '../components/Description'
import { Title } from '../components/Title'
import Wrapper from '../components/Wrapper'

import { MediaItem, MediasGrid } from './styles'

const propTypes = {
  'data-testid': string,
  logos: arrayOf(
    shape({
      description: string,
      logo: object,
      logoMobile: object,
      name: string,
    }),
  ),
  title: string,
}

const defaultProps = {
  logos: [],
}

const MediaLogos = ({ 'data-testid': dataTestId, logos, title, ...others }) => {
  const totalLength = logos?.length || 0
  const [currentLogo, setCurrentLogo] = useState(0)
  const [selectedLogo, setSelectedLogo] = useState(false)
  const { triggerEvent } = useTriggerEvents()

  const currentLogoReference = useRef()
  const selectedLogoReference = useRef()
  const containerRef = useRef()
  const refs = useRef([])

  currentLogoReference.current = currentLogo
  selectedLogoReference.current = selectedLogo
  refs.current = []

  const handleChangeLogos = useCallback(() => {
    if (!selectedLogoReference.current) {
      const newPosition = currentLogoReference.current + 1
      const newCurrentLogo = newPosition > totalLength - 1 ? 0 : newPosition

      setCurrentLogo(newCurrentLogo)

      if (containerRef && isInViewport(containerRef.current)) {
        refs.current[newCurrentLogo]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }
  }, [totalLength])

  useEffect(() => {
    if (!!totalLength) {
      const interval = setInterval(() => {
        handleChangeLogos()
      }, 5000)

      return () => clearInterval(interval)
    }

    return () => {}
  }, [handleChangeLogos, totalLength])

  const onMouseEnter = (index, name) => {
    setCurrentLogo(index)
    setSelectedLogo(true)

    triggerEvent({
      label: `${index + 1} | hover | ${name}`,
      pageInteraction: true,
    })
  }

  const handleClick = (index, name) => {
    triggerEvent({
      label: `${index + 1} | click | ${name}`,
      pageInteraction: true,
    })
  }

  const addToRefs = el => {
    if (el) {
      refs.current.push(el)
    }
  }

  return (
    <Wrapper {...others}>
      <Title>{title}</Title>

      {logos?.[currentLogo] && (
        <Description>{logos[currentLogo].description}</Description>
      )}

      <MediasGrid totalLength={totalLength} ref={containerRef}>
        {logos?.map((logoItem, index) => (
          <MediaItem
            className={currentLogo === index && 'actived-item'}
            data-testid={`${dataTestId}_logoItem-${index}`}
            key={logoItem.name}
            ref={addToRefs}
            onMouseEnter={() => onMouseEnter(index, logoItem.name)}
            onMouseLeave={() => setSelectedLogo(false)}
            logoItem={logoItem}
            onClick={() => handleClick(index, logoItem.name)}
          />
        ))}
      </MediasGrid>
    </Wrapper>
  )
}

MediaLogos.propTypes = propTypes
MediaLogos.defaultProps = defaultProps

export default MediaLogos
