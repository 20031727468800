import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { ImageWrapper } from '../components/ImageWrapper'
import MediasGridComponent from '../components/MediasGrid'

const MediasGrid = styled(MediasGridComponent)(
  ({ theme, totalLength }) => css`
    grid-template-columns: repeat(${totalLength}, 1fr);
    .actived-item {
      opacity: 1;
    }

    ${media.down('2xl')} {
      grid-gap: ${selectTheme('spacingX.16xl')({ theme })};
    }
  `,
)

const MediaItem = styled(ImageWrapper)`
  height: 24px;
  opacity: 0.3;
  transition: opacity 0.4s;

  img {
    top: 0 !important;
    transform: none !important;
    max-height: none !important;
    min-width: none !important;
    object-fit: contain !important;
  }

  .gatsby-image-wrapper {
    min-width: 95px;
    height: 100%;
  }

  ${media.up('5xl')} {
    height: 32px;

    .gatsby-image-wrapper {
      max-width: 120px;
    }
  }
`

export { MediasGrid, MediaItem }
