import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'

const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
`

export { Title }
